import { uploadFileUrl } from '@saturn/api';
import { Form, ImageUploader, Input, Textarea } from '@saturn/uikit';

import { CollapsiblePanel, VisibilityChangeListener } from 'shared/components';
import { onCustomRequest } from 'shared/services/api';
import { fileUrlFormatter } from 'shared/utils';

import useImportedInlineBody from '../../../../shared/hooks/useImportedInlineBody';
import { validationRules } from './constants';

import styles from './ProductOverview.module.scss';

type ProductOverviewProps = {
  header?: string;
};

const SECTION_NAME = 'overview';

export const ProductOverview = ({ header }: ProductOverviewProps) => {
  const productOverviewImageName = [SECTION_NAME, 'fileMetadata'];
  const productOverviewImageInline = useImportedInlineBody(productOverviewImageName);
  return (
    <CollapsiblePanel header={header || 'Product Overview'} name={[SECTION_NAME]}>
      <div className={styles.wrapper}>
        <VisibilityChangeListener section={SECTION_NAME}>
          {required => {
            return (
              <Form.Item
                rules={
                  required
                    ? [
                        {
                          required: true,
                          message: 'Product Overview banner is required',
                        },
                      ]
                    : []
                }
                required={required}
                name={productOverviewImageName}
              >
                <ImageUploader
                  inlineBody={productOverviewImageInline}
                  content="Upload Picture"
                  tooltip="Recommended aspect ratio 3:2"
                  className={styles.image_container}
                  action={uploadFileUrl('banner_image')}
                  customRequest={onCustomRequest}
                  valueFormatter={fileUrlFormatter}
                  style={{
                    width: 265,
                    height: 225,
                  }}
                />
              </Form.Item>
            );
          }}
        </VisibilityChangeListener>
        <Form.Item shouldUpdate>
          {({ getFieldValue }) => {
            const required = getFieldValue([SECTION_NAME, 'visible']);
            return (
              <>
                <Form.Item name={[SECTION_NAME, 'sectionTitle']} rules={required ? validationRules.title : []}>
                  <Input label="Section Title" />
                </Form.Item>
                <Form.Item
                  name={[SECTION_NAME, 'sectionDescription']}
                  rules={required ? validationRules.description : []}
                >
                  <Textarea label="Description" />
                </Form.Item>
              </>
            );
          }}
        </Form.Item>
      </div>
    </CollapsiblePanel>
  );
};
