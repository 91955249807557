import type { FC } from 'react';

import { ScrollableSection } from 'types/scrollableSection';

import { CollapsiblePanel } from 'shared/components';

import { PageSummaryBlock } from './PageSummaryBlock';

interface PageSummaryProps {
  header?: string;
  scrollableSections?: Record<string, ScrollableSection>;
}

export const PageSummary: FC<PageSummaryProps> = ({ header, scrollableSections }) => {
  return (
    <CollapsiblePanel header={header || 'Page Summary'} name={['pageSummary']}>
      <PageSummaryBlock name={['pageSummary', 'sections']} maxItems={6} scrollableSections={scrollableSections} />
    </CollapsiblePanel>
  );
};
