import { FC, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';
import cloneDeep from 'lodash/cloneDeep';
import isNil from 'lodash/isNil';
import set from 'lodash/set';

import { AxiosError, CategoryDataDto, CategoryDetailsDto, FormAndCardViewProductDto, admin } from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, Icon, Input, Spin, notification } from '@saturn/uikit';

import { PageHeader } from 'shared/components';
import { SidebarList } from 'shared/components/SidebarList/SidebarList';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import { PROMPT_MESSAGE } from 'shared/constants';
import {
  getVisibleSections,
  onFormFinishFailed,
  revalidateAfterVisibilityChanged,
  useAdminLocation,
  usePrompt,
} from 'shared/utils';

import { CategorySystemInfo, CategoryTabs } from 'features/categories/components';

import CATEGORY_SECTION_TITLES from '../features/categories/constants';
import ExportImport from '../shared/components/ExportImport/ExportImport';
import ImportPageContext from '../shared/contexts/ImportPageContext';
import useExportImportEnabled from '../shared/hooks/useExportImportEnabled';
import downloadJson from '../shared/utils/downloadJson';
import uploadImportedImages from '../shared/utils/processImportedImages';

import styles from './styles.module.scss';

const CategoryLandingPage: FC = () => {
  const { categoryId = '' } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [isShowLeaveModal, setShowLeaveModal] = useState<boolean>(false);
  const [selectedProducts, updateSelectedProducts] = useState<string[]>([]);
  const [selectedInsurancePartners, updateSelectedInsurancePartners] = useState<string[]>([]);
  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [defaultIndices, setDefaultIndices] = useState<Record<string, DefaultIndexValue>>({});
  const [resetIndices, setResetIndicies] = useState(false);
  const { adminLocation, locationLanguage } = useAdminLocation();
  const [formCategory] = Form.useForm<CategoryDataDto>();
  const [form] = Form.useForm<CategoryDetailsDto>();
  const [indices, setIndices] = useState<IndicesType>([]);
  const [isLandingPageEnabled, setIsLandingPageEnabled] = useState<boolean>(false);
  const [showDoYouWantToImportModal, setShowDoYouWantToImportModal] = useState<boolean>(false);
  const [isImportModalOpen, setIsImportModalOpen] = useState<boolean>(false);

  usePrompt(PROMPT_MESSAGE, isShowLeaveModal);

  const disableProducts = (newSelectedProducts: FormAndCardViewProductDto[]) => {
    const result = [
      ...new Set(newSelectedProducts.map((item: { productId: string }) => item?.productId).filter((id: string) => id)),
    ] as string[];

    updateSelectedProducts(result);
  };

  const disableProviders = (insuranceProviderIds: string[]) => {
    const result = [...new Set(insuranceProviderIds)] as string[];
    updateSelectedInsurancePartners(result);
  };

  //<editor-fold desc="API requests">
  const { data: locationProducts = [] } = useRequest(() => admin.getProductForLocation(adminLocation.id), {
    refreshDeps: [adminLocation.id],
  });

  const { data: category, loading: isCategoryLoading } = useRequest(() => admin.getCategory(categoryId), {
    refreshDeps: [categoryId],
    onSuccess: data => {
      if (data) {
        formCategory.setFieldsValue(data);
        setIsLandingPageEnabled(
          !('hasLandingPage' in data) || ('hasLandingPage' in data && Boolean(data.hasLandingPage)),
        );
      }
    },
    onError: () => {
      const [, locationInPath, categories] = location.pathname.split('/');
      navigate(`/${locationInPath}/${categories}`, { replace: true });
    },
  });

  const setFormDetailsFromResponse = (data: CategoryDetailsDto) => {
    // @ts-ignore
    form.setFieldsValue(data);
    if (data.categoryProducts) {
      disableProducts(data.categoryProducts.products);
    }
    setSectionsVisibility(getVisibleSections(data));
    setDefaultIndices(
      Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          { order: value?.order ?? 0, subSections: value?.subSections ?? null },
        ]),
      ),
    );
  };

  const { data: categoryDetails, loading: isCategoryDetailsLoading } = useRequest(
    () => admin.getCategoryDetails(categoryId, locationLanguage),
    {
      refreshDeps: [categoryId, locationLanguage],
      onSuccess: data => {
        if (data) {
          setFormDetailsFromResponse(data);
        }
      },
      onError: () => {
        const [, locationInPath, categories] = location.pathname.split('/');
        navigate(`/${locationInPath}/${categories}`, { replace: true });
      },
    },
  );

  const { runAsync: updateCategory } = useRequest(admin.updateCategory, { manual: true });
  const { runAsync: updateCategoryDetails } = useRequest(admin.updateCategoryDetails, { manual: true });
  //</editor-fold>

  const { data: providers = [] } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const categoryLandingId = categoryDetails?.id ?? '';
  const {
    runAsync: importPage,
    loading: importLoading,
    data: importedPageData,
  } = useRequest((form: FormData) => admin.importCategoryLandingPage({ categoryLandingId, form }), {
    manual: true,
    onSuccess: response => {
      if (response.content.landingContent) {
        setResetIndicies(true);
        // the problem is in ContentBlockContainer it does need list to be present to actually show the data in form we need copy because in the set function something can be mutating values in place
        const landingContentCopy = cloneDeep(response.content.landingContent);
        setFormDetailsFromResponse({ ...landingContentCopy });
        setTimeout(() => {
          setFormDetailsFromResponse({ ...landingContentCopy });
        }, 100);
        setIsImportModalOpen(false);
        if (response.isFullContent) {
          notification.success({
            message: 'Page imported successfully',
          });
        } else {
          notification.warning({
            message: 'Warning: Content was not fully imported. Please review the page carefully before publishing',
          });
        }
      }
    },
    onError: (e: Maybe<AxiosError>) => {
      if (e.response.data.errors?.length) {
        setIsImportModalOpen(true);
        setShowDoYouWantToImportModal(false);
      }
    },
  });

  const onFormFinish = async () => {
    try {
      const categoryData = await formCategory.validateFields();
      const categoryLandingData = await form.validateFields();

      const indexedValues = indices.reduce((res, item, i) => {
        const itemKey: string = item[0];
        // @ts-ignore
        const block = res[itemKey];
        if (block && typeof block == 'object') {
          block.order = i;
        }
        if (block && 'subSections' in block) {
          const indItem = indices.find(ind => ind[0] === itemKey);
          const itemSubSections = indItem && indItem[1]?.subSections;
          itemSubSections?.forEach((subSection, subSectionIndex) => {
            block.subSections[subSection[0]].order = subSectionIndex;
          });
        }
        return res;
      }, categoryLandingData);

      if (category && locationLanguage) {
        // handle imported images
        const importImages = importedPageData?.content.landingMediaFiles;
        if (importImages?.length) {
          await uploadImportedImages(importImages, indexedValues, results => {
            results.forEach(result => {
              set(indexedValues, result.pathToId, {
                id: result.id,
                mimeType: result?.fileMetadata?.mimeType ?? null,
                title: result.fileMetadata?.title ?? null,
              });
            });
          });
        }

        setShowLeaveModal(false);
        await Promise.all([
          updateCategory(categoryData),
          updateCategoryDetails(categoryId, locationLanguage, indexedValues),
        ]).then(values => {
          if (values.some(value => !isNil(value))) {
            setShowDoYouWantToImportModal(false);
            notification.success({
              message: 'Category has been successfully updated',
            });
          }
          return null;
        });
      }
      return true;
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  const pageTitle = category?.name ?? 'CategoryTitle';
  const exportName = `${adminLocation.id}_${locationLanguage}_Category_${pageTitle}_e1_${new Date().getTime()}`;

  const { runAsync: exportPage, loading: exportLoading } = useRequest(
    () => admin.exportCategoryLandingPage({ categoryLandingId, fileName: exportName }),
    {
      manual: true,
      onSuccess: async ({ base64, fileName }) => {
        downloadJson(base64, fileName);
        notification.success({
          message: 'Page exported successfully',
        });
      },
    },
  );

  const isExportImportEnabled = useExportImportEnabled();

  return (
    <main>
      <Spin spinning={isCategoryLoading && isCategoryDetailsLoading} indicator={<Icon name="sync-outline" />}>
        <Form.Provider onFormFinish={onFormFinish}>
          {category?.name ? <PageHeader title={category?.name || ''} subTitle="Edit Category" /> : null}
          <div className={styles.stickyContainer}>
            <div className={styles.content}>
              {isExportImportEnabled && (
                <ExportImport
                  importLoading={importLoading}
                  exportLoading={exportLoading}
                  onExportPage={exportPage}
                  onImportPage={importPage}
                  hasUnsavedChanges={isShowLeaveModal || showDoYouWantToImportModal}
                  onImportFinished={setShowDoYouWantToImportModal}
                  isImportModalOpen={isImportModalOpen}
                  setIsImportModalOpen={setIsImportModalOpen}
                  onSaveChangesAndExport={async () => {
                    const submitSuccess = await onFormFinish();
                    if (submitSuccess) {
                      await exportPage();
                    }
                  }}
                />
              )}
              <Form
                name="category"
                form={formCategory}
                onFinishFailed={onFormFinishFailed}
                preserve={false}
                onValuesChange={() => {
                  !isShowLeaveModal && setShowLeaveModal(true);
                }}
                initialValues={category}
              >
                <CategorySystemInfo isLandingPageEnabled={isLandingPageEnabled} />
                <Form.Item hidden name="hasLandingPage" valuePropName="checked">
                  <Input type="hidden" />
                </Form.Item>
              </Form>
              <Form
                form={form}
                name="categoryDetails"
                onFinishFailed={onFormFinishFailed}
                preserve={false}
                onValuesChange={(changedValues, allValues) => {
                  if (changedValues.categoryProducts) {
                    disableProducts(allValues.categoryProducts.products);
                  } else if (changedValues.insurancePartners) {
                    disableProviders(allValues.insurancePartners.insuranceProviderIds);
                  }
                  setSectionsVisibility(getVisibleSections(allValues));
                  !isShowLeaveModal && setShowLeaveModal(true);
                }}
                onFieldsChange={(changedFields, allFields) =>
                  revalidateAfterVisibilityChanged(form, changedFields, allFields)
                }
                initialValues={categoryDetails}
              >
                <ImportPageContext.Provider value={importedPageData}>
                  <CategoryTabs
                    isShowLeaveModal={isShowLeaveModal}
                    products={locationProducts}
                    selectedProducts={selectedProducts}
                    sectionTitles={CATEGORY_SECTION_TITLES}
                    selectedInsurancePartners={selectedInsurancePartners}
                    isSecondaryFooterDisclosureVisible={!!categoryDetails?.secondaryFooterDisclosure}
                    providers={providers}
                    isLandingPageEnabled={isLandingPageEnabled}
                  />
                </ImportPageContext.Provider>
              </Form>
            </div>
            <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
              <Button type="primary" htmlType="submit" onClick={onFormFinish}>
                Save
              </Button>
              {isLandingPageEnabled && (
                <Card title="Layout" bordered={false} className={styles.sidebarCardWrapper}>
                  <SidebarList
                    parent="category"
                    resetIndices={resetIndices}
                    setResetIndicies={setResetIndicies}
                    sidebarListValues={sectionsVisibility}
                    sectionTitles={CATEGORY_SECTION_TITLES}
                    defaultIndices={defaultIndices}
                    indices={indices}
                    setIndices={setIndices}
                  />
                </Card>
              )}
            </StickyBox>
          </div>
        </Form.Provider>
      </Spin>
    </main>
  );
};

export default CategoryLandingPage;
