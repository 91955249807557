import { useEffect, useMemo, useState } from 'react';
import type { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import StickyBox from 'react-sticky-box';

import { useRequest } from 'ahooks';

import {
  PRODUCT_TYPE,
  PRODUCT_TYPE_NON_API,
  ProductDetailsDto,
  ProductDto,
  admin,
  formatTimeFieldsForAPI,
} from '@saturn/api';
import { Button, Card, Form, FormSubmitFailed, Icon, Switch, Tooltip, notification } from '@saturn/uikit';

import { PageHeader, SubTitle } from 'shared/components';
import { SidebarList } from 'shared/components/SidebarList/SidebarList';
import { DefaultIndexValue, IndicesType } from 'shared/components/SidebarList/types';
import { getVisibleSections, onFormFinishFailed, useAdminLocation } from 'shared/utils';

import { ProductTabs, SystemInfo } from 'features/product-landing/components';

import PRODUCT_SECTION_TITLES from '../features/product-landing/constants';

import styles from './styles.module.scss';

const ProductCreatePage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { adminLocation, locationLanguage } = useAdminLocation();
  const [formProduct] = Form.useForm<ProductDto>();
  const [formDetails] = Form.useForm<ProductDetailsDto>();
  const [sectionsVisibility, setSectionsVisibility] = useState<Record<string, boolean | null>>({});
  const [isLandingPageEnabledForNonApiProduct, setIsLandingPageEnabledForNonApiProduct] = useState<boolean>(false);
  const [indices, setIndices] = useState<IndicesType>([]);
  const [selectedInsurancePartners, updateSelectedInsurancePartners] = useState<string[]>([]);

  //@ts-ignore
  const defaultIndices: Record<string, DefaultIndexValue> = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(sectionsVisibility)
          .filter(([x]) => !['whatIsCovered', 'whoIsCovered', 'coverage', 'faq', 'document'].includes(x))
          .map(([key]) => [
            key,
            {
              order: 0,
              subSections:
                key === 'allYouNeedToKnow'
                  ? {
                      whatIsCovered: { order: 0 },
                      whoIsCovered: { order: 0 },
                      coverage: { order: 0 },
                      faq: { order: 0 },
                      document: { order: 0 },
                    }
                  : null,
            },
          ]),
      ),
    [sectionsVisibility],
  );

  const { data: providers = [], loading: loadingProviders } = useRequest(
    async () => (await admin.getProvidersList(adminLocation?.id)).map(({ title, id }) => ({ label: title, value: id })),
    {
      refreshDeps: [adminLocation?.id],
    },
  );

  const isSuperSectionVisible = formDetails.getFieldValue(['allYouNeedToKnow', 'visible']);
  useEffect(() => {
    if (!isSuperSectionVisible) {
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'whatIsCovered', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'whoIsCovered', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'coverages', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'faq', 'visible'], false);
      formDetails.setFieldValue(['allYouNeedToKnow', 'subSections', 'documents', 'visible'], false);
    }
  }, [isSuperSectionVisible]);

  useEffect(() => {
    formProduct.setFieldsValue({
      name: '',
      locationId: adminLocation.id,
      providerId: undefined,
      categoryId: undefined,
      type: 'NON_API' as PRODUCT_TYPE,
    });
    setSectionsVisibility(getVisibleSections(formDetails.getFieldsValue()));
  }, []);

  useEffect(() => {
    if (isLandingPageEnabledForNonApiProduct) {
      formDetails.setFieldsValue({
        hero: {
          visible: false,
        },
        pageSummary: {
          visible: false,
          sections: [
            {
              sectionId: '',
            },
            {
              sectionId: '',
            },
            {
              sectionId: '',
            },
          ],
        },
        video: {
          visible: false,
        },
        feature: {
          visible: false,
        },
        about: {
          visible: false,
        },
        callbackWidgetSection: {
          visible: false,
        },
        allYouNeedToKnow: {
          visible: false,
          subSections: {
            whatIsCovered: {
              visible: false,
            },
            whoIsCovered: {
              visible: false,
            },
            coverage: {
              visible: false,
            },
            faq: {
              visible: false,
            },
            document: {
              visible: false,
            },
          },
        },
        contact: {
          visible: false,
        },
        disclosuresFooter: {
          visible: false,
        },
        nextSteps: {
          visible: false,
        },
        whatIsIncludedSection: {
          visible: false,
          cards: [{ order: 0 }, { order: 1 }, { order: 2 }, { order: 3 }],
        },
        benefitsOfInsurance: {
          visible: false,
          cards: [{ order: 0 }, { order: 1 }, { order: 2 }],
        },
        howItWorksSection: {
          visible: false,
          cards: [{ title: '', description: '', cardImage: null }],
        },
        breadCrumb: {},
      });
    }
    setSectionsVisibility(getVisibleSections(formDetails.getFieldsValue()));
  }, [isLandingPageEnabledForNonApiProduct]);

  useEffect(() => {
    setSectionsVisibility(getVisibleSections(formDetails.getFieldsValue()));
  }, [isSuperSectionVisible]);

  const { runAsync: createProduct } = useRequest(admin.createProduct, { manual: true });

  const formFinished = async () => {
    try {
      const productData = await formProduct.validateFields();
      const landingData = await formDetails.validateFields();

      const landingDataWithFormattedTimeFields = formatTimeFieldsForAPI(landingData);

      const landingDataWithOrder = indices
        .filter(
          ([x]) =>
            !['referFriend', 'nextSteps', 'promotionBenefit', 'highlightPlan', 'planLegalDisclaimer'].includes(x),
        )
        .reduce((res, item, i) => {
          const itemKey: string = item[0];
          // @ts-ignore
          const block = res[itemKey];
          if (block && typeof block == 'object') {
            // @ts-ignore
            block.order = i;
          }
          if ('subSections' in block) {
            const indItem = indices.find(ind => ind[0] === itemKey);
            const itemSubSections = indItem && indItem[1]?.subSections;
            itemSubSections?.forEach((subSection, subSectionIndex) => {
              block.subSections[subSection[0]].order = subSectionIndex;
            });
          }
          return res;
        }, landingDataWithFormattedTimeFields);

      if (isLandingPageEnabledForNonApiProduct) {
        await createProduct({
          ...productData,
          landing: {
            ...landingDataWithOrder,
            landingType: isLandingPageEnabledForNonApiProduct ? 'EXTENDED' : 'COMPACT',
            language: locationLanguage ?? '',
          },
        });
      } else {
        await createProduct({
          ...productData,
          landing: {
            language: locationLanguage ?? '',
            landingType: isLandingPageEnabledForNonApiProduct ? 'EXTENDED' : 'COMPACT',
            info: landingData.info,
            seo: landingData.seo,
          },
        });
      }
      notification.success({
        message: 'Product has been successfully created',
      });
      const [, locationInPath, products] = location.pathname.split('/');
      navigate(`/${locationInPath}/${products}`, { replace: true });
    } catch (e) {
      onFormFinishFailed(e as FormSubmitFailed);
    }
  };

  const disableProviders = (insuranceProviderIds: string[]) => {
    const providers = insuranceProviderIds;
    const result = [...new Set(providers)] as string[];
    updateSelectedInsurancePartners(result);
  };

  return (
    <main>
      <PageHeader title="Create NON-API product" />
      <Form.Provider onFormFinish={formFinished}>
        <div className={styles.stickyContainer}>
          <div className={styles.content}>
            <Form name="product" form={formProduct} onFinishFailed={onFormFinishFailed} preserve={false}>
              <SystemInfo
                type={PRODUCT_TYPE_NON_API}
                isSlugRequiredForExtendedProduct={isLandingPageEnabledForNonApiProduct}
                providers={providers}
                loadingProviders={loadingProviders}
              />
            </Form>
            <Form
              name="productDetails"
              form={formDetails}
              onFinishFailed={onFormFinishFailed}
              onValuesChange={(changedValues, allValues) => {
                if (changedValues.insurancePartners) {
                  disableProviders(allValues.insurancePartners.insuranceProviderIds);
                }
                setSectionsVisibility(getVisibleSections(allValues));
              }}
              preserve={true}
            >
              <SubTitle>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span>Landing page</span>
                  <div style={{ display: 'flex', gap: '10px' }}>
                    <Switch
                      checkedChildren="on"
                      unCheckedChildren="off"
                      checked={isLandingPageEnabledForNonApiProduct}
                      onChange={() => {
                        setIsLandingPageEnabledForNonApiProduct?.(prevState => !prevState);
                      }}
                    />
                    <Tooltip
                      title={
                        'Tooltip for landing page switcher: the landing page for a non API product can be adjusted just like for an API product. You cannot turn OFF/ON landing page for a created non-API product.'
                      }
                    >
                      <Icon size={25} name="question-mark-circle-outline" />
                    </Tooltip>
                  </div>
                </div>
              </SubTitle>
              <ProductTabs
                type={PRODUCT_TYPE_NON_API}
                isShowLeaveModal={false}
                sectionTitles={PRODUCT_SECTION_TITLES}
                isNewProduct={true}
                isLandingPageEnabledForNonApiProduct={isLandingPageEnabledForNonApiProduct}
                providers={providers}
                selectedInsurancePartners={selectedInsurancePartners}
              />
            </Form>
          </div>
          <StickyBox offsetTop={20} offsetBottom={20} className={styles.stickyBlock}>
            <Button type="primary" htmlType="submit" onClick={formFinished}>
              Create
            </Button>
            {isLandingPageEnabledForNonApiProduct && (
              <Card title="Page Layout" bordered={false} className={styles.sidebarCardWrapper}>
                <SidebarList
                  parent="product"
                  sidebarListValues={sectionsVisibility}
                  sectionTitles={PRODUCT_SECTION_TITLES}
                  indices={indices}
                  defaultIndices={defaultIndices}
                  setIndices={setIndices}
                />
              </Card>
            )}
          </StickyBox>
        </div>
      </Form.Provider>
    </main>
  );
};

export default ProductCreatePage;
